export default function Partner() {
  return (
    <section className="text-center padding_top_35 padding_bottom_60">
      <div className="container">
        <p
          className="margin_bottom_20 font_weight_500 font_size_18"
          style={{ color: "#222" }}
        >
          Chứng chỉ và đối tác
        </p>
        <figure className="partner_img d-flex" style={{justifyContent: 'center', gap: '32px', flexWrap: 'wrap'}}>
          <img
            src="assets/partner/922.png"
            alt="922"
            height={30}
            width={110}
          />
          <img
            src="assets/partner/anti_ddos.jpg"
            alt="anti_ddos"
            height={40}
          />
          <img
            src="assets/partner/aws.png"
            alt="aws"
            height={40}
          />
          <img
            src="assets/partner/azure.png"
            alt="azure"
            height={40}
          />
          <img
            src="assets/partner/dmca.png"
            alt="dcma"
            height={40}
          />
          <img
            src="assets/partner/FPT_Telecom.png"
            alt="FPT_Telecom"
            height={40}
          />
          <img
            src="assets/partner/OVH.png"
            alt="OVH"
            height={40}
          />
          <img
            src="assets/partner/Viettel.png"
            alt="OVH"
            height={40}
          />
          <img
            src="assets/partner/VNPT.png"
            alt="OVH"
            height={40}
          />
          <img
            src="assets/partner/World_IPv6.png"
            alt="World_IPv6"
            height={40}
          />
        </figure>
      </div>
    </section>
  );
}
