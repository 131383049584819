const URL = {
  Home: "/",
  SLA: "/sla",
  SLADetail: "/sla-detail",
  Guide: "/guide",
  Guide1: "/guide1",
  Guide2: "/guide2",
  Guide3: "/guide3",
  Agreement: "/agreement",
  Contact: "/contact",
  ListServerVN: "/list-server-vn",
  ListServerSG: "/list-server-sg",
  ListServerDE: "/list-server-de",
  ListServerFR: "/list-server-fr",
  ListServerNL: "/list-server-nl",
  ListServerAU: "/list-server-au",
  ListServerUK: "/list-server-uk",
  ListServerUS: "/list-server-us",
  ListServerCA: "/list-server-ca",
  ListServerEU: "/list-server-eu",
  ListVPSGPU: "/list-vps-gpu",
  ListProxyVN: "/list-proxy-vn",
  ListProxySG: "/list-proxy-sg",
  ListProxyDE: "/list-proxy-de",
  ListProxyFR: "/list-proxy-fr",
  ListProxyAU: "/list-proxy-au",
  ListProxyUK: "/list-proxy-uk",
  ListProxyUS: "/list-proxy-us",
  ListProxyCA: "/list-proxy-ca",
  ListProxyNL: "/list-proxy-nl",
  ListDedicatedServerEU: "/list-dedicated-server-eu",
  ListDedicatedServerUS: "/list-dedicated-server-us",
  List922S5Proxy: "/list-922-s5proxy",
  ListPIAS5Proxy: "/list-pia-s5proxy",
  BuyProduct: "https://manage.myvps.vn/buying?type=VPS&nation=VN",
  Login: "https://manage.myvps.vn",
  Register: "https://manage.myvps.vn/register",
  ProductManagement:
    "https://manage.myvps.vn/management?type=VPS&by_time=using",
  NotFound: "*",
};

export default URL;
