export default function Feedback() {
  return (
    <section className="padding_top_60 padding_bottom_60 text-center">
      <div className="container">
        <h2 className="margin_bottom_20 font_size_36 line_height_48 color_222">
          PHẢN HỒI TỪ KHÁCH HÀNG
        </h2>
        <div className="row">
          <div className="col" />
          <div className="col-xl-10 col-lg-10 margin_top_50">
            <div
              id="testimonial_slider"
              className="carousel slide"
              data-ride="carousel"
            >
              {/* Indicators */}
              <ul className="carousel-indicators testimonial_indicators">
                <li
                  data-target="#testimonial_slider"
                  data-slide-to={0}
                  className="active"
                >
                  <figure>
                    <img src="assets/images/testimonial_img01.png" alt="" />
                  </figure>
                </li>
                <li
                  data-target="#testimonial_slider"
                  data-slide-to={1}
                  className=""
                >
                  <figure>
                    <img src="assets/images/testimonial_img02.png" alt="" />
                  </figure>
                </li>
                <li
                  data-target="#testimonial_slider"
                  data-slide-to={2}
                  className=""
                >
                  <figure>
                    <img src="assets/images/testimonial_img05.png" alt="" />
                  </figure>
                </li>
                <li
                  data-target="#testimonial_slider"
                  data-slide-to={3}
                  className=""
                >
                  <figure>
                    <img src="assets/images/testimonial_img04.png" alt="" />
                  </figure>
                </li>
                <li
                  data-target="#testimonial_slider"
                  data-slide-to={4}
                  className=""
                >
                  <figure>
                    <img src="assets/images/testimonial_img03.png" alt="" />
                  </figure>
                </li>
                <li
                  data-target="#testimonial_slider"
                  data-slide-to={5}
                  className=""
                >
                  <figure>
                    <img src="assets/images/testimonial_img03.png" alt="" />
                  </figure>
                </li>
              </ul>
              {/* The slideshow */}
              <div className="carousel-inner">
                <div className="carousel-item testimonial_box_content active">
                  <figure className="margin_bottom_15">
                    <img src="assets/images/testimonial_quotes.png" alt="" />
                  </figure>
                  <div className="client_name margin_bottom_20">Mạnh Hùng</div>
                  <p>
                    Dịch vụ VPS, Server của MyVPS thực sự khiến tôi rất hài
                    lòng, nhân viên chăm sóc khách hàng tận tâm, nhiệt tình, hỗ
                    trợ fix lỗi nhanh chóng. Tôi hoàn toàn yên tâm khi sử dụng
                    dịch vụ tại đây. Chất lượng tốt, kỹ thuật có tâm nhất định
                    sẽ đồng hành cùng MYVPS trong thời gian tới.
                  </p>
                </div>
                <div className="carousel-item testimonial_box_content">
                  <figure className="margin_bottom_15">
                    <img src="assets/images/testimonial_quotes.png" alt="" />
                  </figure>
                  <div className="client_name margin_bottom_20">Hải Nam</div>
                  <p>
                    Sau hơn 4 năm sử dụng dịch vụ của MyVPS, chúng tôi rất hài
                    lòng về chất lượng dịch vụ và chi phí hợp lý. Đội ngũ kỹ
                    thuật và chăm sóc khách hàng tận tình, giúp giảm bớt
                    workload cho team vận hành của công ty, nhất là khi công ty
                    của mình không có đội ngũ IT chuyên môn.
                  </p>
                </div>
                <div className="carousel-item testimonial_box_content">
                  <figure className="margin_bottom_15">
                    <img src="assets/images/testimonial_quotes.png" alt="" />
                  </figure>
                  <div className="client_name margin_bottom_20">Minh Nhật</div>
                  <p>
                    Hơn 1 năm qua, doanh nghiệp tôi sử dụng Firewall Anti DDoS
                    bên MyVPS thì không gặp bất cứ vấn đề nào. Đội ngũ kỹ thuật
                    hỗ trợ rất nhiệt tình khi gặp vấn đề. Doanh nghiệp kinh
                    doanh nên dùng.
                  </p>
                </div>
                <div className="carousel-item testimonial_box_content">
                  <figure className="margin_bottom_15">
                    <img src="assets/images/testimonial_quotes.png" alt="" />
                  </figure>
                  <div className="client_name margin_bottom_20">
                    Nguyễn Hoàng
                  </div>
                  <p>
                    Mình đã sử dụng Server của MyVPS hơn 1 năm qua. Thật sự rất
                    ấn tượng về tốc độ load web và tính bảo mật cực cao. Không
                    những thế mà các bạn còn hỗ trợ rất nhiệt tình, dù là đêm
                    khuya hay ngày nghỉ. Các doanh nghiệp kinh doanh rất nên
                    dùng.
                  </p>
                </div>
                <div className="carousel-item testimonial_box_content">
                  <figure className="margin_bottom_15">
                    <img src="assets/images/testimonial_quotes.png" alt="" />
                  </figure>
                  <div className="client_name margin_bottom_20">Tiện Cloud</div>
                  <p>
                    Chúng tôi đã dùng dịch vụ VPS của MyVPS được vài năm rồi và
                    bây giờ mọi thứ rất ổn định.
                  </p>
                </div>
                <div className="carousel-item testimonial_box_content">
                  <figure className="margin_bottom_15">
                    <img src="assets/images/testimonial_quotes.png" alt="" />
                  </figure>
                  <div className="client_name margin_bottom_20">Phan Hải</div>
                  <p>
                    Tôi đã mua VPS được một thời gian và chưa bao giờ gặp sự cố.
                    Đội ngũ hỗ trợ nhanh và nhiệt tình hết sức.
                  </p>
                </div>
              </div>
            </div>
            {/* Left and right controls */}
            <a
              className="carousel-control-prev"
              href="#testimonial_slider"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" />
            </a>
            <a
              className="carousel-control-next"
              href="#testimonial_slider"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" />
            </a>
          </div>
          <div className="col" />
        </div>
      </div>
    </section>
  );
}
