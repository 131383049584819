/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import URL from "constant/url";
// import { useNavigate } from "react-router-dom";

export default function Footer() {
  // const navigate = useNavigate();
  const domain = window.location.hostname;
  const logo_r = `https://buffer.httv.workers.dev/assets/${domain}/logo/logo-r.png`;

  return (
    <>
      <section className="bg_0b257f padding_top_50 padding_bottom_50">
        <div className="container">
          <div className="row footer03_box">
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="footer03_box_inner footer03_box">
                <figure>
                  <img src={logo_r} alt="logo" className="logo" />
                </figure>
                <p className="margin_bottom_20">
                  Đơn vị hàng đầu cung cấp các giải pháp hạ tầng IT, điện toán
                  đám mây.
                </p>
                <p>
                  Chất lượng tốt nhất <br /> Chi phí rẻ nhất <br /> An toàn -
                  Bảo mật <br /> Hỗ trợ tận tâm 24/7.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="footer02_links footer03_links">
                <ul className="footer02_contact_details_list">
                  <li>
                    <i className="fas fa-map-marker-alt" /> Địa chỉ
                    <div>
                      Ngõ 102 Tô Vĩnh Diện, phường Khương Trung, quận Thanh
                      Xuân, Hà Nội
                    </div>
                  </li>
                  <li>
                    <i className="fas fa-phone" />
                    <a href="tel:+(84)919931503">+(84) 919.931.503</a>
                  </li>
                  <li>
                    <i className="fas fa-envelope" />
                    <a href="mailto:admin@myvps.vn">admin@myvps.vn</a>
                  </li>
                </ul>
                <ul
                  className="footer_social_links"
                  style={{ marginTop: "20px" }}
                >
                  <li>
                    <a href="https://t.me/myvpsvn">
                      <i className="fab fa-telegram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/myvps.vn">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/myvpsvn/">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/myvpsvn">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 footer01_links_mobile">
              <div className="footer_01_box_inner">
                <div className="margin_bottom_20 footer03_heading">
                  Thông tin
                </div>
                <ul className="footer03_links footer03_links_list">
                  <li>
                    <a href="/">Trang chủ</a>
                  </li>
                  <li>
                    <a href="/contact">Liên hệ</a>
                  </li>
                  <li>
                    <a href="/agreement">Điều khoản</a>
                  </li>
                  <li>
                    <a href="/sla">SLA</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 footer01_links_mobile">
              <div className="footer_01_box_inner">
                <div className="margin_bottom_20 footer03_heading">
                  Sản phẩm
                </div>
                <ul className="footer03_links footer03_links_list">
                  <li>
                    <a href="/list-server-vn">VPS</a>
                  </li>
                  <li>
                    <a href="/list-dedicated-server-eu">Dedicated Server</a>
                  </li>
                  <li>
                    <a href="/list-proxy-vn">Proxy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="d-md-none"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                className="footer_01_box_inner"
                style={{ marginRight: "64px" }}
              >
                <div className="margin_bottom_20 footer03_heading">
                  Thông tin
                </div>
                <ul className="footer03_links footer03_links_list">
                  <li>
                    <a href="/">Trang chủ</a>
                  </li>
                  <li>
                    <a href="/contact">Liên hệ</a>
                  </li>
                  <li>
                    <a href="/agreement">Điều khoản</a>
                  </li>
                  <li>
                    <a href="/sla">SLA</a>
                  </li>
                </ul>
              </div>
              <div className="footer_01_box_inner">
                <div className="margin_bottom_20 footer03_heading">
                  Sản phẩm
                </div>
                <ul className="footer03_links footer03_links_list">
                  <li>
                    <a href="/list-server-vn">VPS</a>
                  </li>
                  <li>
                    <a href="/list-dedicated-server-eu">Dedicated Server</a>
                  </li>
                  <li>
                    <a href="/list-proxy-vn">Proxy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid bg_ecedf1 padding_top_10 padding_bottom_10 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 mobile_center">
              <div className="">© All rights reserved by MYVPS</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
