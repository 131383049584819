import URL from "constant/url";

export default function BuyProductBanner() {
  return (
    <>
      {/*Build Your Website*/}
      <section className="padding_top_60 padding_bottom_60 text-center">
        <div className="container">
          <div className="coa_block">
            <h2 className="margin_bottom_20 font_size_36 line_height_48 color_222">
              Trải nghiệm chất lượng vượt trội cùng MYVPS
            </h2>
            <p className="margin_bottom_40">
              Chúng tôi tự tin với kinh nghiệm 10 năm kinh doanh, sẵn sàng đem
              đến cho khách hàng những dịch vụ chất lượng nhất.
            </p>
            <a href={URL.BuyProduct} className="learn-more">
              <div className="circle">
                <span className="icon arrow" />
              </div>
              <p className="button-text">Mua ngay</p>
            </a>
          </div>
        </div>
      </section>
      {/*Ends Here*/}
    </>
  );
}
