/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { actionGetListPlan, selectListPlanDetail } from "./slice";
import { useAppDispatch, useAppSelector } from "store";
import URL from "constant/url";
import { nanoid } from "@reduxjs/toolkit";
import { MANAGEMENT_URL, SITE_NAME } from "constant/app";

export default function FeaturePlan() {
  const dispatch = useAppDispatch();
  const listPlanDetail = useAppSelector(selectListPlanDetail);

  useEffect(() => {
    dispatch(actionGetListPlan({ featured: true, site_name: SITE_NAME }));
  }, [dispatch]);

  return (
    <>
      <div className="row justify-content-center">
        {listPlanDetail.map((item, index) => (
          <div className="col-xl-4 col-lg-4 col-md-4 margin_box" key={nanoid()}>
            <div className={`hosting_plan_box ${index === 1 ? "active" : ""}`}>
              <div
                className="hosting_plan_name"
                style={{ fontSize: "32px", marginBottom: "32px" }}
              >
                VPS {item.region}
              </div>
              <div
                className="hosting_plan_box_price"
                style={{ fontSize: "24px" }}
              >
                {item.price}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  marginTop: "-32px",
                  color: `${index === 1 ? "#fff" : ""}`,
                }}
              >
                VNĐ/Tháng
              </div>
              <ul className="hosting_plan_listing">
                <li>CPU: {item.cpu}</li>
                <li>RAM: {item.ram}</li>
                <li>SSD: {item.ssd}</li>
                <li>IPv4: {item.ipv4}</li>
                <li>Bandwidth: {item.bandwidth}</li>
                <li>Ethernet port: {item.ethernet_port}</li>
              </ul>
              <a
                href={`${MANAGEMENT_URL}/buying?type=VPS&nation=${item.region}`}
                className="learn-more"
              >
                <div className="circle">
                  <span className="icon arrow" />
                </div>
                <p className="button-text">Mua ngay</p>
              </a>
            </div>
          </div>
        ))}
      </div>
      <section className="text-center padding_top_35" style={{ width: "100%" }}>
        <div className="container">
          <p
            className="margin_bottom_10 font_weight_500 font_size_18"
            style={{ color: "#222" }}
          >
            Đa dạng các quốc gia
          </p>
          <figure
            className="partner_img d-flex"
            style={{ justifyContent: "center", gap: "32px", flexWrap: "wrap" }}
          >
            <div style={{ display: "grid" }}>
              <a href={URL.ListServerAU}>
                <img
                  src="assets/flags/australia.png"
                  alt="australia"
                  className="img-fluid"
                  width={110}
                  height={80}
                />
              </a>
              <span>VPS Úc (AU)</span>
            </div>
            <div style={{ display: "grid" }}>
              <a href={URL.ListServerCA}>
                <img
                  src="assets/flags/canada.png"
                  alt="canada"
                  className="img-fluid"
                  width={110}
                  height={80}
                />
              </a>
              <span>VPS Canada (CA)</span>
            </div>
            <div style={{ display: "grid" }}>
              <a href={URL.ListServerFR}>
                <img
                  src="assets/flags/france.png"
                  alt="france"
                  className="img-fluid"
                  width={110}
                  height={80}
                />
              </a>
              <span>VPS Pháp (FR)</span>
            </div>
            <div style={{ display: "grid" }}>
              <a href={URL.ListServerDE}>
                <img
                  src="assets/flags/germany.png"
                  alt="germany"
                  className="img-fluid"
                  width={110}
                  height={80}
                />
              </a>
              <span>VPS Đức (DE)</span>
            </div>
            <div style={{ display: "grid" }}>
              <a href={URL.ListServerUK}>
                <img
                  src="assets/flags/united-kingdom.png"
                  alt="united-kingdom"
                  className="img-fluid"
                  width={110}
                  height={80}
                />
              </a>
              <span>VPS Anh (UK)</span>
            </div>
            <div style={{ display: "grid" }}>
              <a href={URL.ListServerEU}>
                <img
                  src="assets/flags/european-union.png"
                  alt="european-union"
                  className="img-fluid"
                  width={110}
                  height={80}
                />
              </a>
              <span>VPS Châu Âu (PTU)</span>
            </div>
          </figure>
        </div>
      </section>
    </>
  );
}
