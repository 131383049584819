/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */

// import FeaturePlan from "components/FeaturePlan";
import { useAppDispatch } from "store";
import { actionGetListPlan } from "components/FeaturePlan/slice";
import { useEffect } from "react";
import FeaturePlan from "components/FeaturePlan";
import BuyProductBanner from "./BuyProductBanner";
import Partner from "./Partner";
import Feedback from "./Feedback";
import URL from "constant/url";
// import { nanoid } from "@reduxjs/toolkit";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Content() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actionGetListPlan({ featured: true, site_name: "myvps" }));
  }, [dispatch]);
  return (
    <>
      {/*Hero Banner Section*/}
      <section className="hero_banner_img padding_bottom_35  float-left w-100">
        <div className="banner_content">
          <div className="container">
            <div className="row mobile_banner_margin">
              <div className="col-xl-6 col-lg-6 col-md-6  padding_top_200 order02 mobile_text_center">
                <h1 className="font_size_48 line_height_60 color_222 margin_bottom_0">
                  VPS - SERVER - PROXY
                </h1>
                <h1
                  className="line_height_60 color_222 margin_bottom_0"
                  style={{ fontSize: "24px" }}
                >
                  Chính hãng - Chất lượng - Giá rẻ
                  <br />
                </h1>
                <p className="font_size_18 line_height_30 color_5a5f69 margin_bottom_30 font_weight_500">
                  Giá chỉ từ 79.000 VNĐ/tháng
                </p>
                <a href={URL.Register} className="learn-more">
                  <div className="circle">
                    <span className="icon arrow" />
                  </div>
                  <p className="button-text">Trải nghiệm ngay</p>
                </a>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 order01 mobile_img">
                <figure>
                  <img
                    src="assets/images/hero_banner_img.svg"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Slider Section*/}
      <section className="text-center padding_top_35 padding_bottom_60">
        <div className="container">
          <p
            className="margin_bottom_10 font_weight_500 font_size_18"
            style={{ color: "#222" }}
          >
            Hệ Điều Hành Hỗ Trợ
          </p>
          <p className="margin_bottom_50 font_weight_500 font_size_16">
            MYVPS hỗ trợ đầy đủ các hệ điều hành, cài đặt nhanh chóng, thuận
            tiện chỉ với một click chuột.
          </p>
          <figure
            className="partner_img"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "32px",
              flexWrap: "wrap",
            }}
          >
            <img
              src="assets/os/window.png"
              alt="window"
              className="img-fluid"
              width={80}
              height={80}
            />
            <img
              src="assets/os/ubuntu.png"
              alt="ubuntu"
              className="img-fluid"
              width={80}
              height={80}
            />
            <img
              src="assets/os/centos.png"
              alt="centos"
              className="img-fluid"
              width={80}
              height={80}
            />
          </figure>
        </div>
      </section>
      {/*Hosting Plans Section*/}
      <section className="padding_top_60 padding_bottom_60 text-center">
        <div className="container">
          <h2 className="margin_bottom_20 font_size_36 line_height_48 color_222">
            DỊCH VỤ NỔI BẬT
          </h2>
          <p className="margin_bottom_50" style={{ fontSize: "16px" }}>
            MYVPS cung cấp các gói sản phẩm - dịch vụ ưu việt, cấu hình tối ưu
            với giá cả hợp lý.
          </p>
          <div
            className="row margin_bottom_20"
            style={{ justifyContent: "center" }}
          >
            <FeaturePlan />
          </div>
        </div>
      </section>
      {/*End Here*/}
      {/*Hosting Features Plans*/}
      <section className="padding_top_60 padding_bottom_35 text-center">
        <div className="container">
          <h2 className="margin_bottom_20 font_size_36 line_height_48 color_222">
            CHÀO MỪNG BẠN ĐẾN VỚI MYVPS
          </h2>
          <p className="padding_bottom_100" style={{ fontSize: "16px" }}>
            Chúng tôi cung cấp nhiều dịch vụ khác nhau với giá thành hợp lý.
          </p>
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-5 welcome_section_img">
              <figure>
                <img
                  src="assets/images/welcome_section_img.svg"
                  alt=""
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="col" />
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="row margin_bottom_40">
                <div className="col-xl-4 col-lg-4 col-md-4 col-4">
                  <div className="company_box01 hover_effect">
                    <div
                      className="company_detail_heading"
                      style={{ fontSize: "12px" }}
                    >
                      DEDICATED SERVER
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-4">
                  <div className="company_box02 hover_effect">
                    <div
                      className="company_detail_heading"
                      style={{ fontSize: "12px" }}
                    >
                      VPS <br /> (SSD - NVME - GPU)
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-4">
                  <div className="company_box03 hover_effect">
                    <div
                      className="company_detail_heading"
                      style={{ fontSize: "12px" }}
                    >
                      PROXY
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-left margin_bottom_40 mobile_center">
                Mọi thông tin khách hàng được mã hóa. Hạn chế rủi ro hệ thống.
                Bảo đảm dữ liệu bảo mật và an toàn.
                Mọi thao tác cài đặt và quản lý đều tự động và dễ dàng sử dụng
                trong phần Quản Trị.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*Ends Here*/}
      {/* Guarantee Section*/}
      <section className="padding_bottom_45 padding_top_35 text-center">
        <div className="container">
          <h2 className="margin_bottom_20 font_size_36 line_height_48 color_222">
            VÌ SAO NÊN CHỌN MYVPS?
          </h2>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 padding_top_170 margin_box">
              <div className="guarantee_box">
                <div className="guarantee_img01" />
                <div className="guarantee_box_heading margin_bottom_10">
                  Chính Sách Hoàn Trả
                </div>
                <p>
                  Nếu quý khách không hài lòng với dịch vụ, chúng tôi sẽ hỗ trợ
                  hoàn lại tiền không cần lý do.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="guarantee_box margin_box mobile_margin_box">
                <div className="guarantee_img02" />
                <div className="guarantee_box_heading margin_bottom_10">
                  Dịch vụ đa dạng
                </div>
                <p>
                  Cung cấp nhiều sản phẩm - dịch vụ khác nhau, từ VPS, Proxy đến
                  các giải pháp server chuyên nghiệp, đáp ứng mọi nhu cầu của
                  khách hàng.
                </p>
              </div>
              <div className="guarantee_box margin_box mobile_margin_box">
                <div className="guarantee_img03" />
                <div className="guarantee_box_heading margin_bottom_10">
                  Chất lượng - An toàn, Bảo mật
                </div>
                <p>
                  Luôn đem đến cho khách hàng những sản phẩm chất lượng nhất,
                  với giá thành cạnh tranh, đảm báo tính ổn định và độ tin cậy
                  cao nhất trên thị trường.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 padding_top_170">
              <div className="guarantee_box">
                <div className="guarantee_img04" />
                <div className="guarantee_box_heading margin_bottom_10">
                  Hỗ trợ 24/7
                </div>
                <p>
                  Đội ngũ hỗ trợ và hệ thống giám sát hoạt động 24/7 giúp phát
                  hiện nhanh chóng các sự cố, phản hồi nhanh chóng, đảm bảo dịch
                  vụ của khách hàng vận hành xuyên suốt và ổn định.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Ends Here*/}
      <Partner />
      <Feedback />
      <BuyProductBanner />
    </>
  );
}
